@import "nprogress/nprogress.css";

/*
  The following styles are copied from the stylesheet imported
  above with all colors overridden using Tailwind.

  todo: is there a less hacky way to style this?
*/

#nprogress {
  position: fixed;
  top: 0;
}

#nprogress .bar {
  background: currentColor;
  z-index: 9999;
}

#nprogress .peg {
  box-shadow:
    0 0 10px currentColor,
    0 0 5px currentColor;
  z-index: 9999;
}

#nprogress {
  z-index: 9999;
}

#nprogress .spinner-icon {
  border-top-color: currentColor;
  border-left-color: currentColor;
  z-index: 9999;
}
