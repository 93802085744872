/* buttons */

.hide_touch .MuiTouchRipple-root {
  @apply hidden;
}

/* scrollbars */

.hide_scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

.hide_scrollbar-track::-webkit-scrollbar {
  display: none;
}

/* menus  */

#tooltip_menu .MuiTooltip-tooltip {
  min-width: 160px;
  @apply border;
  @apply rounded-lg;
  @apply shadow-dropdown;
}

#tooltip_menu .MuiTooltip-tooltip {
  @apply px-0;
}

#tooltip_menu .MuiTooltip-tooltip {
  @apply border-layer-700;
  @apply text-layer-200;
}

#tooltip_menu .MuiTooltip-tooltip {
  background: theme(backgroundColor.layer.900) !important;
}

/* modals */

.backdrop_dark .MuiBackdrop-root:not(.MuiBackdrop-invisible) {
  @apply backdrop-blur-sm;
}

.legacy_modal .MuiBackdrop-root:not(.MuiBackdrop-invisible) {
  @apply backdrop-blur-sm;
}

.legacy_modal .MuiModal-root .MuiDialog-container .MuiPaper-root {
  max-width: 420px;
  max-height: calc(100% - theme(spacing.16));
  @apply m-8;
  @apply w-full;
  @apply p-8;
  @apply backdrop-blur-md;
  @apply border;
  @apply rounded-xl;
  @apply shadow-modal;
}

.legacy_modal .MuiModal-root .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root {
  @apply p-0;
  @apply text-base;
  @apply font-medium;
}

.legacy_modal .MuiModal-root .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
  @apply px-0;
  @apply pt-2;
  @apply pb-8;
}

.legacy_modal .MuiModal-root .MuiDialog-container .MuiPaper-root .MuiDialogContentText-root {
  @apply text-base;
  @apply leading-tight;
  @apply font-normal;
}

.legacy_modal .MuiModal-root .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
  @apply p-0;
  @apply flex;
  @apply items-center;
  @apply justify-end;
  @apply space-x-2;
}

.faster_dialog .MuiPaper-root .MuiDialogActions-root .MuiButtonBase-root {
  @apply h-8;
  @apply rounded-md;
  @apply normal-case;
  @apply border;
  @apply border-solid;
  @apply shadow-button;
}

.backdrop_dark .MuiBackdrop-root:not(.MuiBackdrop-invisible) {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.legacy_modal .MuiModal-root .MuiDialog-container .MuiPaper-root,
.faster_dialog .MuiPaper-root {
  @apply bg-layer-900/90;
  @apply bg-none;
  @apply border-layer-700;
  @apply text-layer-300;
}

.legacy_modal .MuiModal-root .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root {
  @apply text-layer-50;
}

.legacy_modal .MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContentText-root {
  @apply text-layer-200;
}

.legacy_modal .MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogActions-root
  .MuiButtonBase-root:first-of-type,
.faster_dialog
  .MuiPaper-root
  .MuiDialogActions-root
  .MuiButtonBase-root:first-of-type {
  @apply bg-layer-900;
  @apply border-layer-700;
  @apply text-layer-200;
  @apply hover:bg-layer-700/50;
  @apply hover:text-layer-50;
}

/* modal input */

.legacy_modal .MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContent-root
  .MuiFormControl-root {
  @apply mt-0;
}

.legacy_modal .MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiInputLabel-standard:not(.MuiInputLabel-shrink) {
  @apply text-layer-600;
}

.legacy_modal .MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiInputLabel-shrink {
  @apply text-layer-500;
}

.legacy_modal .MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiInputBase-input {
  @apply text-layer-300;
}

.legacy_modal .MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiInputBase-root:before {
  border-bottom: 1px solid theme(colors.layer.600);
}

.legacy_modal .MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContent-root
  .MuiFormControl-root
  .MuiInputBase-root:after {
  border-bottom: 1px solid theme(colors.violet.400);
}

/* inputs */

.input {
  @apply w-full;
  @apply rounded-lg;
}

.input .MuiInputBase-root {
  @apply rounded-lg;
}

.input .MuiInputBase-root {
  @apply bg-transparent;
}

.input .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  @apply rounded-lg;
}

.input .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}

.input .MuiInputBase-root input {
  @apply text-layer-100;
}

.input .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  @apply border-layer-600;
}

.input .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
  color: theme(colors.layer.50) !important;
}

.input .MuiInputLabel-shrink:not(.Mui-focused) {
  color: theme(colors.layer.400) !important;
}

.input .MuiInputLabel-shrink {
  color: theme(colors.layer.400) !important;
}

.input .MuiInputBase-input {
  @apply text-layer-100;
}

.input .MuiInputBase-root:before {
  border-bottom: 1px solid theme(colors.layer.700);
}

.input .MuiInputBase-root:after {
  border-bottom: 1px solid theme(colors.violet.300);
}

.input
  .MuiInputBase-root
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  @apply bg-layer-800;
}

.input .MuiInputBase-root .Mui-focused .MuiOutlinedInput-notchedOutline {
  @apply bg-layer-800;
  @apply border-layer-600;
}

.input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: theme(borderColor.layer.600) !important;
}

.input .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: theme(borderColor.layer.700) !important;
}

.input_dark {
  @apply w-full;
  @apply rounded;
}

.input_dark .MuiInputBase-root {
  @apply rounded;
}

.input_dark .MuiInputBase-root input {
  @apply text-layer-50;
}

.input_dark .MuiInputBase-root {
  @apply bg-transparent;
}

.input_dark .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  @apply border-layer-600/75;
  @apply rounded;
}

.input_dark .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
  color: theme(colors.layer.50) !important;
  @apply placeholder:text-layer-300;
}

.input_dark .MuiInputLabel-sizeSmall:not(.MuiInputLabel-shrink) {
  margin-top: -5px;
  color: theme(colors.layer.300) !important;
}

.input_dark .MuiInputLabel-shrink:not(.Mui-focused) {
  color: theme(colors.layer.50) !important;
}

.input_dark .MuiInputLabel-shrink {
  color: theme(colors.layer.50) !important;
}

.input_dark .MuiInputBase-input {
  @apply text-layer-50;
  @apply bg-layer-800;
  @apply text-sm;
}

.input_dark .MuiInputBase-root:before {
  border-bottom: 1px solid theme(colors.layer.600/75);
}

.input_dark .MuiInputBase-root:after {
  border-bottom: 1px solid theme(colors.violet.400);
}

.input_dark
  .MuiInputBase-root
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  @apply bg-layer-700;
}

.input_dark .MuiInputBase-root .Mui-focused .MuiOutlinedInput-notchedOutline {
  @apply bg-layer-800;
}

.input_dark .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: theme(borderColor.layer.500) !important;
}

.input_dark .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: theme(borderColor.layer.600) !important;
  border-width: 1px !important;
}

/* select */
.select .MuiInputBase-input {
  padding-left: theme(spacing[1.5]) !important;
}

.select .MuiOutlinedInput-notchedOutline {
  border-radius: theme(borderRadius.DEFAULT) !important;
  border-width: 1px !important;
}

.MuiPopover-root .MuiPaper-root .MuiMenuItem-root .select_item {
  width: calc(100% + 1rem) !important;
  @apply -ml-2;
  @apply px-2;
  @apply h-8;
  @apply flex;
  @apply flex-grow;
  @apply items-center;
  @apply rounded-md;
  @apply text-sm;
  @apply font-normal;
}

.MuiPopover-root .MuiPaper-root .MuiMenuItem-root .select_item {
  @apply hover:ring-1;
  @apply hover:ring-inset;
}

.MuiPopover-root .MuiPaper-root .Mui-selected .select_item {
  @apply font-medium;
  @apply ring-1;
  @apply ring-inset;
}

.select_label {
  color: theme(colors.layer.400) !important;
}

.select .MuiInputBase-input {
  color: theme(colors.layer.300) !important;
}

.select .MuiOutlinedInput-notchedOutline {
  border-color: theme(borderColor.layer.600) !important;
}

.select .MuiSelect-icon {
  @apply text-layer-600;
}

.MuiPopover-root .MuiPaper-root .MuiMenuItem-root .select_item {
  @apply hover:bg-layer-700;
  @apply ring-layer-600/25;
}

.MuiPopover-root .MuiPaper-root .Mui-selected .select_item {
  @apply bg-layer-700;
  @apply text-layer-50;
  @apply ring-layer-600/25;
}

/* toggle */

.toggle .Mui-selected {
  background: transparent;
  backgroundcolor: theme(colors.layer.800);
  box-shadow: theme(boxShadow.none);
  @apply ring-1;
  @apply ring-inset;
  @apply ring-layer-600;
}

/* slider */

.MuiSlider-thumb {
  width: theme(width.4) !important;
  height: theme(height.4) !important;
}

.MuiSlider-thumb:before {
  box-shadow: none !important;
}

.MuiSlider-rail {
  @apply bg-transparent !important;
}

.MuiSlider-mark {
  @apply hidden;
}

.MuiSlider-rail {
  box-shadow: 0 0 0 1px theme(colors.layer.300);
}
