@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* scrollbar */

.overflow_y_scroll {
  @apply overflow-y-auto;
}

@media (min-width: 640px) {
  .overflow_y_scroll {
    @apply overflow-y-scroll;
  }
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: #595968;
  border: 5px solid #20212D;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #858699;
  border: 4px solid #20212D;
}

::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
}

/* drive */

.w_drive_list {
  width: calc(100% - 32px) !important;
}

html:not(.dark) {
  .custom_grid_item_icon_unselected {
    @apply text-layer-300;
  }

  .custom_grid_item_icon_unselected .custom_grid_item_icon_child--share {
    @apply text-layer-900;
  }

  .custom_grid_item_icon_unselected .custom_grid_item_icon_child--circle {
    @apply text-layer-300;
  }

  .custom_grid_item_icon_selected {
    @apply text-violet-600;
  }

  .custom_grid_item_icon_selected .custom_grid_item_icon_child--share {
    @apply text-violet-50;
  }

  .custom_grid_item_icon_selected .custom_grid_item_icon_child--circle {
    @apply text-violet-600;
  }
}

html:not(.light) {
  .custom_grid_item_icon_unselected {
    @apply text-layer-900;
  }

  .custom_grid_item_icon_unselected .custom_grid_item_icon_child--share {
    @apply text-layer-300;
  }

  .custom_grid_item_icon_unselected .custom_grid_item_icon_child--circle {
    @apply text-layer-900;
  }

  .custom_grid_item_icon_selected {
    @apply text-violet-500;
  }

  .custom_grid_item_icon_selected .custom_grid_item_icon_child--share {
    @apply text-violet-50;
  }

  .custom_grid_item_icon_selected .custom_grid_item_icon_child--circle {
    @apply text-violet-500;
  }
}

html:not(.dark) {
  .group\/button:not(.Mui-selected) {
    .mask_brush_shadow {
      filter: drop-shadow(2px 1px 0px #fff) drop-shadow(-1px -1px 0px #fff)
        drop-shadow(1px -2px 0px #fff) drop-shadow(-1px 1px 0px #fff);
    }
  }

  .group\/button {
    .mask_brush_shadow {
      filter: drop-shadow(2px 1px 0px #7c3aed)
        drop-shadow(-1px -1px 0px #7c3aed) drop-shadow(1px -2px 0px #7c3aed)
        drop-shadow(-1px 1px 0px #7c3aed);
    }
  }

  .group\/button:not(.Mui-selected):hover {
    .mask_brush_shadow {
      filter: drop-shadow(2px 1px 0px #eff1f4)
        drop-shadow(-1px -1px 0px #eff1f4) drop-shadow(1px -2px 0px #eff1f4)
        drop-shadow(-1px 1px 0px #eff1f4) !important;
    }
  }
}

html:not(.light) {
  .group\/button:not(.Mui-selected) {
    .mask_brush_shadow {
      filter: drop-shadow(2px 1px 0px #282a30)
        drop-shadow(-1px -1px 0px #282a30) drop-shadow(1px -2px 0px #282a30)
        drop-shadow(-1px 1px 0px #282a30) !important;
    }
  }

  .group\/button {
    .mask_brush_shadow {
      filter: drop-shadow(2px 1px 0px #8b5cf6)
        drop-shadow(-1px -1px 0px #8b5cf6) drop-shadow(1px -2px 0px #8b5cf6)
        drop-shadow(-1px 1px 0px #8b5cf6);
    }
  }

  .group\/button:not(.Mui-selected):hover {
    .mask_brush_shadow {
      filter: drop-shadow(2px 1px 0px #3c4149)
        drop-shadow(-1px -1px 0px #3c4149) drop-shadow(1px -2px 0px #3c4149)
        drop-shadow(-1px 1px 0px #3c4149) !important;
    }
  }
}

.toggle_section {
  border: none !important;
  border-radius: 0 !important;
}

.toggle_section_summary {
  min-height: unset !important;
  @apply text-sm;
  @apply px-2.5 !important;
  @apply h-8;
  @apply gap-x-1.5;
}

.toggle_section_collapsed .MuiCollapse-root {
  height: 0px !important;
}

.toggle_section .MuiCollapse-root {
  overflow-y: unset;
}

.toggle_section_details {
  border-top: 0px !important;
  overflow-y: unset !important;
  padding: 0 !important;
}

@keyframes moveLeft {
  0% {
    left: 0px;
  }

  10% {
    left: 0px;
  }

  100% {
    left: -32px;
  }
}

.toolbar_back_button {
  position: absolute;
  -webkit-animation: 0.5s ease 0s normal forwards 1 moveLeft;
  animation: 0.5s ease 0s normal forwards 1 moveLeft;
}

.onboarding_step_mask {
  -webkit-mask-image: linear-gradient(180deg, black 0%, transparent 50%), linear-gradient(to bottom, black 0%, transparent 70%);
  mask-image: linear-gradient(180deg, black 0%, transparent 50%), linear-gradient(to bottom, black 0%, transparent 70%);
}

.onboarding_step_1_dots {
  background-size: 24px 24px;
}

html:not(.light) {
  .arrow_pointer_drop_shadow {
    filter: drop-shadow(1px 1px theme(colors.layer.900)) drop-shadow(-1px -1px theme(colors.layer.900)) drop-shadow(1px -1px theme(colors.layer.900)) drop-shadow(-1px 1px theme(colors.layer.900));
  }

  .onboarding_step_1_dots {
    background-image: radial-gradient(theme(colors.violet.700) 1px, transparent 0);
    -webkit-mask-image: radial-gradient(circle at center, theme(colors.black) 0, transparent 100%);
    mask-image: radial-gradient(circle at center, theme(colors.black) 0, transparent 100%);
  }
}

html:not(.dark) {
  .arrow_pointer_drop_shadow {
    filter: drop-shadow(1px 1px theme(colors.layer.50)) drop-shadow(-1px -1px theme(colors.layer.50)) drop-shadow(1px -1px theme(colors.layer.50)) drop-shadow(-1px 1px theme(colors.layer.50));
  }

  .onboarding_step_1_dots {
    background-image: radial-gradient(black 1px, transparent 0);
    -webkit-mask-image: radial-gradient(circle at center, black 0, transparent 100%);
    mask-image: radial-gradient(circle at center, black 0, transparent 100%);
  }
}

.after_border_bottom:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 22px;
}

.image_zoom img {
  opacity: 1;
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.image_zoom.zoomed {
  @apply cursor-zoom-out !important;
}
.image_zoom.loading {
  @apply hidden !important;
}


.image_zoom figure :not(#imageZoom) {
  display: none !important;
}

.image_zoom:before, .image_zoom:after {
  display: none !important;
}

html:not(.light) {
  .after_border_bottom:after {
    background-color: #3a3d45;
  }
}

html:not(.dark) {
  .after_border_bottom:after {
    background-color: #b3b5be;
  }
}

.style_selection_dropdown_search:before {
  border: 1px solid !important;
  @apply rounded-md;
  @apply h-full;
}

.style_selection_dropdown_search:after {
  border: 1px solid !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
  @apply rounded-md;
  @apply h-full;
}


html:not(.light) {
  .style_selection_dropdown_search:before {
    border-color: rgba(255, 255, 255, 0) !important;
  }
  .style_selection_dropdown_search:after {
    border-color: rgba(255, 255, 255, 0.1) !important;
  }
}

html:not(.dark) {
  .style_selection_dropdown_search:before, .style_selection_dropdown_search:after {
    border-color: theme(colors.layer.300) !important;
  }
}


.intercom-launcher-frame, .intercom-lightweight-app-launcher {
  height: 32px !important;
  width: 32px !important;
  border-radius: 8px !important;
  background-color: theme(colors.layer.800) !important;
  display: block !important;
  opacity: 0 !important;
}

.intercom-launcher-frame {
  transform: translateX(20px) translateY(20px) !important;
}
